@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.framework-card {
  background: rgba(0, 41, 155, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.btn {
  color: #3572ef;
  background: transparent;
  border: 3px solid #3572ef;
  border-radius: 30px;
  transition: all 0.5s ease;
}

.background-glass {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.glassmorphism {
  padding: 40px;
  /* background: url('./assets/about.png'); */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.navbar {
  border-radius: 50px;
  background: linear-gradient(145deg, #eaeaea, #eaeaea);
  box-shadow: 5px 5px 10px #000313, -5px -5px 10px #020d47;
}

.btn:hover {
  background: #0092ff;
  color: #100e34;
  box-shadow: 0 8px 32px 0 #0092ff;
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border: 3px solid rgba(255, 255, 255, 0.18);
}

.gradient,
.active {
  background: #3572ef;
}

.active {
  color: #eaeaea;
  padding: 20px;
  border-radius: 100%;
}

.background-gray {
  background: #212121;
  transition: all 0.5s ease;
}

.background-gray:hover {
  background: #535353;
}

.blue-gradient {
  background: rgb(0, 146, 255);
  background: linear-gradient(
    187deg,
    rgba(0, 146, 255, 1) 0%,
    rgba(0, 67, 97, 1) 100%
  );
}

.swiper-pagination-bullet-active {
  background-color: #eaeaea;
}

.live2d-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
}


#svg-global {
  zoom: 1.2;
  overflow: visible;
}

@keyframes fade-particles {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes floatUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}

#particles {
  animation: fade-particles 5s infinite alternate;
}
.particle {
  animation: floatUp linear infinite;
}

.p1 {
  animation-duration: 2.2s;
  animation-delay: 0s;
}
.p2 {
  animation-duration: 2.5s;
  animation-delay: 0.3s;
}
.p3 {
  animation-duration: 2s;
  animation-delay: 0.6s;
}
.p4 {
  animation-duration: 2.8s;
  animation-delay: 0.2s;
}
.p5 {
  animation-duration: 2.3s;
  animation-delay: 0.4s;
}
.p6 {
  animation-duration: 3s;
  animation-delay: 0.1s;
}
.p7 {
  animation-duration: 2.1s;
  animation-delay: 0.5s;
}
.p8 {
  animation-duration: 2.6s;
  animation-delay: 0.2s;
}
.p9 {
  animation-duration: 2.4s;
  animation-delay: 0.3s;
}

@keyframes bounce-lines {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

#line-v1,
#line-v2,
#node-server,
#panel-rigth,
#reflectores,
#particles {
  animation: bounce-lines 3s ease-in-out infinite alternate;
}
#line-v2 {
  animation-delay: 0.2s;
}

#node-server,
#panel-rigth,
#reflectores,
#particles {
  animation-delay: 0.4s;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-secondary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-secondary font-semibold text-[20px] mb-6 leading-[46px];
  }
  .btn {
    @apply font-tertiary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base from-[#FF0000] to-[#950101] hover:from-[#950101] hover:to-[#FF0000];
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text  from-[#e6b400] hover:to-[#e69b00] font-bold font-secondary hover:from-[#4F48EC] to-[#75BFEC];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
